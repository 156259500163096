.menu-container {
    float: right;
    padding-top: 4px;
}

.nav-item {
    font: 26px 'BlenderMedium';
    color: white;
    text-decoration: none;
    padding-left: 5px;
}

.css-177ic5c {
    background-color: #16161D !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #16161D;
    border-radius: 10px;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    background-color: transparent !important;
    right: 0 !important;
    left: unset !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: transparent !important;
}