.header {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.headliner {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text-container {
    margin-top: 100px;
    padding: 15px;
    width: 290px;
    height: 125px;
    display: inline-block;
    background-color: #1c1c24;
    border: 1px solid #FFF4B8;
    border-radius: 10px;
}

.headliner-text {
    font: 34px 'BlenderMedium';
    color: white;
    line-height: 40px;
}
.headliner-subtext {
    font: 22px 'BlenderBook';
    color: #ddd;
}

.launch-app-header {
    width: 200px;
    margin: 25px auto auto;
}

.mockup {
    position: relative;
    width: 100%;
    max-width: 560px;
    height: 60vw;
    max-height: 430px;
    margin: 25px auto auto;
}

.mockup-img {
    width: 200px;
}
