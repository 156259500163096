.app-intro-img {
    width: 250px;
    border: 1px solid #363649;
    border-radius: 10px;
}

.app-intro-selects {
    padding: 15px;
    width: 220px;
    background-color: #1c1c24;
    border: 1px solid #363649;
    border-radius: 10px;
    margin: 0 auto auto;
}

.select-container {
    margin: 10px;
}

.select-btn {
    font: 21px 'BlenderBold';
    background-color: #FFFDA4;
    padding: 3px;
    border: none;
    border-radius: 5px;
    color: black;
    width: 200px;
}

.select-btn:focus {
    background: #363649;
    color: white;
}
.select-btn:active {
    background: #363649;
    color: white;
}
.select-btn:hover {
    cursor: pointer;
}