@font-face {
    font-family: 'BlenderBold';
    src: local('BlenderBold'), url(./fonts/BlenderPro-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'BlenderBoldItalic';
    src: local('BlenderBoldItalic'), url(./fonts/BlenderPro-BoldItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'BlenderBook';
    src: local('BlenderBook'), url(./fonts/BlenderPro-Book.ttf) format('truetype');
}
@font-face {
    font-family: 'BlenderBookItalic';
    src: local('BlenderBookItalic'), url(./fonts/BlenderPro-BookItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'BlenderHeavy';
    src: local('BlenderHeavy'), url(./fonts/BlenderPro-Heavy.ttf) format('truetype');
}
@font-face {
    font-family: 'BlenderMedium';
    src: local('BlenderMedium'), url(./fonts/BlenderPro-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'BlenderMediumItalic';
    src: local('BlenderMediumItalic'), url(./fonts/BlenderPro-MediumItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'BlenderThin';
    src: local('BlenderThin'), url(./fonts/BlenderPro-Thin.ttf) format('truetype');
}
@font-face {
    font-family: 'BlenderThinItalic';
    src: local('BlenderThinItalic'), url(./fonts/BlenderPro-ThinItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'SukarRegular';
    src: local('SukarRegular'), url(./fonts/SukarRegular.otf) format('opentype');
}
@font-face {
    font-family: 'SukarBold';
    src: local('SukarBold'), url(./fonts/SukarBold.otf) format('opentype');
}
@font-face {
    font-family: 'SukarBlack';
    src: local('SukarBlack'), url(./fonts/SukarBlack.otf) format('opentype');
}

#root {
    margin: -8px;
}