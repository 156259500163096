.seekr-app-link {
    background: linear-gradient(90deg, rgba(255,232,89,1) 0%, rgba(255,237,136,1) 23%, rgba(255,244,189,1) 100%);
    border-radius: 10px;
    padding: 10px 16px 10px 18px;
}

.seekr-link-text {
    font: 30px 'BlenderBold';
    color: black !important;
    text-decoration: none;
}
