.logo-name-container {
    display: inline-flex;
}

.home-logo-link {
    text-decoration: none;
}

.logo-text {
    font: 30px 'BlenderBold';
    color: #FFFDA4;
    padding-top: 10px;
    margin-left: 57px;
    width: 186px;
}

.logo-img {
    height: 36px;
    float: left;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-top: 10px;
}

